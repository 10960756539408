import { useState } from 'react';
import { useStoreWithArray } from '../stores';
import APIService from '../../../../apps/mooc-frontend/src/services/APIService';
import config from '../../../../apps/mooc-frontend/src/consts/config';
import * as Sentry from '@sentry/browser';

export default function useFeedbackSubmit(
    actionId: number,
    setSubmitted?: (type: FeedbackPayload['type']) => void,
) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { setErrorMessage, setCurrentModal } = useStoreWithArray([
        'setErrorMessage',
        'setCurrentModal',
    ]);

    const handleFeedbackSubmit = async (
        type: FeedbackPayload['type'],
        categories: string[] = [],
        message = '',
    ) => {
        const api = new APIService(config.REACT_APP_INTERACTIONS_URL!);

        const payload: FeedbackPayload = {
            agent_action_id: actionId,
            type,
            category: categories,
            message,
        };

        try {
            await api.post('user_feedback/agent_action', {
                ...payload,
            });

            if (setSubmitted) {
                setSubmitted(type);
            }
            setIsModalOpen(false);
        } catch (error) {
            Sentry.captureException(error);
            setErrorMessage(
                'Something went wrong submitting the feedback, please try again.',
            );
            setCurrentModal('error');
        }
    };

    return { handleFeedbackSubmit, isModalOpen, setIsModalOpen };
}
