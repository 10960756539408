import React, { useContext } from 'react';
import StudentFeaturesContext from '../../components/contexts/StudentFeaturesContext';
import axios from 'axios';
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Skeleton,
    Spacer,
    TabList,
    TabPanel,
    TabPanels,
    TabProps,
    Tabs,
    useMultiStyleConfig,
    useTab,
    VStack,
} from '@chakra-ui/react';
import DashboardPageLayout from '../../components/layouts/DashboardPageLayout';
import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { moocAPI } from '../../services';
import { courseCompare } from '../CoursesPage';
import Section, {
    CarouselSection,
    CollapsibleSection,
    COURSE_CARD_SPACING,
} from './Section';
import UserActionsAvatar from '../../components/layouts/DashboardPageLayout/UserActionsAvatar';
import CourseCard from '../../components/courses/CourseCardV2';
import config from '../../consts/config';

const getTrialCourses = async () => {
    const resp = await axios.get<Course[]>(
        config.REACT_APP_TRIAL_LOCKED_COURSES_URL!,
        {
            headers: { Accept: 'application/json' },
        },
    );
    return resp.data;
};

const useTrialLockedCourses = () => {
    const { trialData } = useContext(StudentFeaturesContext);
    const isEnabled =
        trialData.is_trial && !!config.REACT_APP_TRIAL_LOCKED_COURSES_URL;
    const { data } = useQuery(['trial-locked-courses'], getTrialCourses, {
        enabled: isEnabled,
    });

    return { data, isEnabled };
};

const CustomTab = React.forwardRef<HTMLElement, TabProps>((props, ref) => {
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref });
    const isSelected = tabProps['aria-selected'];

    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps);
    const { borderBottom } = styles.tab;
    const css = { ...styles.tab, borderColor: 'transparent' };

    return (
        <Button __css={css} {...tabProps}>
            <Box
                as='span'
                p={1}
                __css={isSelected ? { borderBottom } : undefined}
            >
                {tabProps.children}
            </Box>
        </Button>
    );
});
CustomTab.displayName = 'CustomTab';

export const useCoursesQueries = (
    status: ('available' | 'in-progress' | 'completed')[],
): UseQueryResult<Course[]>[] => {
    return useQueries({
        queries: status.map(status => {
            return {
                queryKey: ['courses', status],
                queryFn: async () =>
                    ((await moocAPI.get(
                        `courses/?status=${status}`,
                    )) as Course[]).sort((c1, c2) =>
                        courseCompare(c1, c2, status),
                    ),
            };
        }),
    });
};

const CoursesPageV2: React.FC = props => {
    const { data: userData, isSuccess: isUserDataReady } = useQuery(
        ['user-data'],
        () => moocAPI.get('student/user'),
    );

    const [
        availableCoursesQuery,
        inProgressCoursesQuery,
        completedCoursesQuery,
    ] = useCoursesQueries(['available', 'in-progress', 'completed']);

    const {
        data: trialLockedCourses,
        isEnabled: shouldShowLockedCourses,
    } = useTrialLockedCourses();
    const showCoursesTab =
        !!availableCoursesQuery.data?.length ||
        availableCoursesQuery.isLoading ||
        !!inProgressCoursesQuery.data?.length ||
        inProgressCoursesQuery.isLoading ||
        shouldShowLockedCourses;
    const showInProgressTab =
        !!inProgressCoursesQuery.data?.length ||
        !!completedCoursesQuery.data?.length;

    return (
        <DashboardPageLayout>
            <Tabs
                w='100%'
                colorScheme='brand.black'
                mt='-15px'
                height='max-content'
            >
                <TabList borderBottom='none'>
                    {showCoursesTab && (
                        <CustomTab
                            color='brand.black.400'
                            _selected={{ color: 'brand.black.800' }}
                        >
                            Courses
                        </CustomTab>
                    )}
                    {showInProgressTab && (
                        <CustomTab
                            color='brand.black.400'
                            _selected={{ color: 'brand.black.800' }}
                        >
                            My progress
                        </CustomTab>
                    )}
                </TabList>

                <Box
                    py={4}
                    my={3}
                    borderColor={'gray.400'}
                    borderBottomWidth={'1px'}
                >
                    <Skeleton isLoaded={isUserDataReady}>
                        <Center>
                            <Heading
                                size='lg'
                                as='h2'
                                fontWeight='normal'
                                className='fs-exclude'
                            >
                                {isUserDataReady
                                    ? `Welcome, ${userData.first_name} ${userData.last_name}`
                                    : 'Welcome'}
                            </Heading>
                            <Spacer />
                            <UserActionsAvatar />
                        </Center>
                    </Skeleton>
                </Box>

                <TabPanels>
                    {showCoursesTab && (
                        <TabPanel p={0}>
                            <VStack spacing={10} mt={5}>
                                {(inProgressCoursesQuery.isLoading ||
                                    !!inProgressCoursesQuery.data?.length) && (
                                    <CarouselSection
                                        heading={'Continue'}
                                        subHeading={'Jump back in'}
                                        isLoaded={
                                            inProgressCoursesQuery.isSuccess
                                        }
                                        courses={inProgressCoursesQuery.data}
                                        courseCardVariant='in-progress'
                                    />
                                )}

                                {(availableCoursesQuery.isLoading ||
                                    !!availableCoursesQuery.data?.length) && (
                                    <CollapsibleSection
                                        forceOpen={
                                            inProgressCoursesQuery.isSuccess &&
                                            !inProgressCoursesQuery.data?.length
                                        }
                                        heading='Try something new'
                                        subHeading='More courses for you'
                                        courseCardVariant='un-enrolled'
                                        isLoaded={
                                            availableCoursesQuery.isSuccess
                                        }
                                        courses={availableCoursesQuery.data}
                                    />
                                )}

                                {!!trialLockedCourses?.length && (
                                    <Section
                                        heading={
                                            <Box mt={4}>
                                                Please upgrade for full catalog
                                            </Box>
                                        }
                                        isLoaded
                                        courses={trialLockedCourses}
                                        noSkeletonItems
                                    >
                                        <Flex
                                            gap={COURSE_CARD_SPACING}
                                            wrap='wrap'
                                        >
                                            {trialLockedCourses?.map(
                                                (course, i) => (
                                                    <CourseCard
                                                        key={i}
                                                        variant='locked'
                                                        course={course}
                                                    />
                                                ),
                                            )}
                                        </Flex>
                                    </Section>
                                )}
                            </VStack>
                        </TabPanel>
                    )}
                    {showInProgressTab && (
                        <TabPanel p={0}>
                            <VStack spacing={10} mt={5}>
                                {!!inProgressCoursesQuery.data?.length && (
                                    <CarouselSection
                                        heading='My progress'
                                        courseCardVariant={'in-progress'}
                                        isLoaded={
                                            inProgressCoursesQuery.isSuccess
                                        }
                                        courses={inProgressCoursesQuery.data}
                                    />
                                )}

                                {!!completedCoursesQuery.data?.length && (
                                    <CollapsibleSection
                                        forceOpen={
                                            inProgressCoursesQuery.isSuccess &&
                                            !inProgressCoursesQuery.data.length
                                        }
                                        heading='Completed courses'
                                        courseCardVariant={'completed'}
                                        isLoaded={
                                            completedCoursesQuery.isSuccess
                                        }
                                        courses={completedCoursesQuery.data}
                                    />
                                )}
                            </VStack>
                        </TabPanel>
                    )}
                </TabPanels>
            </Tabs>
        </DashboardPageLayout>
    );
};

export default CoursesPageV2;
