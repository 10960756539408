import { useCallback, useEffect, useRef, useState } from 'react';

export enum MicrophoneState {
    NotInitialized = 'not_initialized',
    Initializing = 'initializing',
    Ready = 'ready',
    Recording = 'recording',
    Stopping = 'stopping',
    Error = 'error',
}

export interface AudioConfig {
    sampleRate?: number;
    bufferSize?: number;
}

export function useMicrophone(
    onData: (data: Int16Array) => void,
    { sampleRate = 16000, bufferSize = 1024 }: AudioConfig = {},
) {
    const [state, setState] = useState<MicrophoneState>(
        MicrophoneState.NotInitialized,
    );
    const audioContextRef = useRef<AudioContext | null>(null);
    const processorRef = useRef<ScriptProcessorNode | null>(null);
    const streamRef = useRef<MediaStream | null>(null);

    const cleanup = useCallback(() => {
        if (processorRef.current) {
            processorRef.current.disconnect();
            processorRef.current = null;
        }

        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }

        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }

        setState(MicrophoneState.NotInitialized);
    }, []);

    const start = useCallback(async () => {
        try {
            setState(MicrophoneState.Initializing);

            const onaudioprocess = (e: AudioProcessingEvent) => {
                const inputData = e.inputBuffer.getChannelData(0);
                // Convert Float32Array to Int16Array (16-bit PCM format)
                const int16Array = new Int16Array(inputData.length);
                for (let i = 0; i < inputData.length; i++) {
                    int16Array[i] = Math.min(1, inputData[i]) * 0x7fff;
                }
                onData(int16Array);
            };

            // Always get a fresh context and stream if we're starting from NotInitialized
            if (state === MicrophoneState.NotInitialized) {
                const audioContext = new AudioContext({ sampleRate });
                const mediaStream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        noiseSuppression: true,
                        echoCancellation: true,
                        sampleRate,
                    },
                });

                const source = audioContext.createMediaStreamSource(
                    mediaStream,
                );
                const processor = audioContext.createScriptProcessor(
                    bufferSize,
                    1,
                    1,
                );

                processor.onaudioprocess = onaudioprocess;

                source.connect(processor);
                processor.connect(audioContext.destination);

                // Store refs
                audioContextRef.current = audioContext;
                processorRef.current = processor;
                streamRef.current = mediaStream;

                setState(MicrophoneState.Ready);
            }

            setState(MicrophoneState.Recording);
        } catch (err) {
            cleanup();
            setState(MicrophoneState.Error);
            console.error('Failed to start microphone:', err);
            throw err;
        }
    }, [state, cleanup, onData, sampleRate, bufferSize]);

    const stop = useCallback(() => {
        if (state === MicrophoneState.Recording) {
            setState(MicrophoneState.Stopping);
            cleanup();
        }
    }, [state, cleanup]);

    const toggle = useCallback(
        async (shouldRecord: boolean) => {
            if (shouldRecord && state !== MicrophoneState.Recording) {
                await start();
            } else if (!shouldRecord && state === MicrophoneState.Recording) {
                stop();
            }
        },
        [start, stop, state],
    );

    // Cleanup on unmount
    useEffect(() => cleanup, [cleanup]);

    return {
        start,
        stop,
        toggle,
        state,
    };
}
