import React, { useHistory, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import ROUTES from '../consts/routes';
import Interaction from './Interaction';

const InteractionPage = () => {
    const history = useHistory();
    const { courseId, activityId } = useParams<{
        courseId: string;
        activityId: string;
    }>();

    const goToActivityPage = useCallback(() => {
        history.replace(
            `/${ROUTES.COURSES}/${courseId}/${ROUTES.ACTIVITY}/${activityId}`,
        );
    }, [courseId, activityId, history]);

    return (
        <Interaction
            activityId={activityId}
            courseId={courseId}
            onClose={goToActivityPage}
        />
    );
};
export default InteractionPage;
