import { authService, keycloak } from './AuthenticationService';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import {
    API_SERVICE_NOT_AVAILABLE,
    UNRECOGNISED_API_ERROR,
} from '../consts/errors';

export class APIError extends Error {
    route: string;
    responseBody: any;

    constructor(message: string, route: string, responseBody: any) {
        super(message);
        this.name = 'APIError';
        this.responseBody = responseBody;
        this.route = route;
    }
}

export default class APIService {
    baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    absoluteUrl(route: string): string {
        return `${this.baseUrl}${route}`;
    }

    private errorHandler(error: any, route: string): void {
        let e = error;
        if (error.message === 'Failed to fetch') {
            e = new APIError(API_SERVICE_NOT_AVAILABLE, route, null);
        }
        Sentry.withScope(scope => {
            scope.setExtras({
                apiBase: this.baseUrl,
                apiRoute: route,
            });
            Sentry.captureException(e);
        });
        throw e;
    }

    private async makeAuthenticatedRequest(
        route: string,
        method: string,
        body?: any,
        refreshAuth = true,
    ): Promise<any> {
        if (refreshAuth && keycloak.isTokenExpired(5)) {
            await keycloak.updateToken(5).catch(() => {
                // Failed to refresh the token, or the session has expired
                authService.logout();
            });
        }

        return fetch(this.absoluteUrl(route), {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${keycloak.token}`,
            },
            body: JSON.stringify(body),
        })
            .then(response =>
                Promise.all([
                    response.ok,
                    response.status,
                    response.headers.get('Content-Type') === 'application/json'
                        ? response.json()
                        : null,
                ]),
            )
            .then(([responseOk, responseStatus, responseBody]) => {
                return this.responseHandler(
                    [responseOk, responseStatus, responseBody],
                    route,
                );
            })
            .catch(e => this.errorHandler(e, route));
    }

    private async makeUnauthenticatedRequest(
        route: string,
        method: string,
        body?: any,
    ): Promise<any> {
        return fetch(this.absoluteUrl(route), {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(response =>
                Promise.all([
                    response.ok,
                    response.status,
                    response.headers.get('Content-Type') === 'application/json'
                        ? response.json()
                        : null,
                ]),
            )
            .then(([responseOk, responseStatus, responseBody]) => {
                return this.responseHandler(
                    [responseOk, responseStatus, responseBody],
                    route,
                );
            })
            .catch(e => this.errorHandler(e, route));
    }

    responseHandler(
        [responseOk, responseStatus, responseBody]: [
            boolean,
            number,
            any | null,
        ],
        route: string,
    ): any {
        if (responseOk) {
            return responseBody;
        }

        Sentry.withScope(scope => {
            scope.setExtras({
                apiBase: this.baseUrl,
                apiRoute: route,
                status: responseStatus,
                body: responseBody,
            });
            Sentry.captureMessage('Unsuccessful API request');
        });

        if (responseStatus === 401) {
            authService.logout();
            return null;
        }

        if (responseStatus === 404) {
            return null;
        }

        if (responseBody) {
            if (responseBody['code']) {
                const e = new Error(
                    responseBody['message'] || responseBody['code'],
                );
                e.name = responseBody['code'];
                throw e;
            } else {
                // {
                //   "field_name": [{"code": "string", "message": "validation error"}, ...]
                // },
                Object.entries(responseBody).forEach(([_, v]) => {
                    if (Array.isArray(v) && v.length > 0) {
                        const firstError = v[0];
                        if (firstError['code']) {
                            const e = new Error(
                                firstError['message'] || firstError['code'],
                            );
                            e.name = firstError['code'];
                            throw e;
                        }
                    }
                });
            }
        }
        throw new APIError(UNRECOGNISED_API_ERROR, route, responseBody);
    }

    get(route: string, unauthenticated?: boolean): Promise<any> {
        unauthenticated =
            unauthenticated === undefined
                ? keycloak.token === null
                : unauthenticated;

        if (unauthenticated) {
            return this.makeUnauthenticatedRequest(route, 'GET');
        }
        return this.makeAuthenticatedRequest(route, 'GET');
    }

    post(route: string, body?: any, unauthenticated?: boolean): Promise<any> {
        if (unauthenticated) {
            return this.makeUnauthenticatedRequest(route, 'POST', body);
        }
        return this.makeAuthenticatedRequest(route, 'POST', body);
    }

    patch(route: string, body?: any, unauthenticated?: boolean): Promise<any> {
        if (unauthenticated) throw Error('Unauthenticated PATCH not supported');
        return this.makeAuthenticatedRequest(route, 'PATCH', body);
    }

    put(url: string, body?: any, unauthenticated?: boolean): Promise<any> {
        if (unauthenticated) {
            return this.makeUnauthenticatedRequest(url, 'PUT', body);
        }
        return this.makeAuthenticatedRequest(url, 'PUT', body);
    }

    putFile(
        route: string,
        file: File,
        onUploadProgress?: (_: ProgressEvent) => void,
    ): Promise<any> {
        const data = new FormData();
        data.append('file', file);

        return axios
            .put(`${route}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: onUploadProgress,
            })
            .then(response => response.data)
            .catch(err => {
                // Response handler is used here just for error handling
                // axios throws an error when the response's status is not in [200, 299]
                return this.responseHandler(
                    [
                        200 <= err.response.status &&
                            err.response.status <= 299, // Will always be false
                        err.response.status,
                        err.response.data,
                    ],
                    route,
                );
            });
    }

    delete(route: string, body?: any, unauthenticated?: boolean): Promise<any> {
        if (unauthenticated) throw Error('Unauthenticated PATCH not supported');
        return this.makeAuthenticatedRequest(route, 'DELETE', body);
    }
}
