import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useAsyncError from '../hooks/useAsyncError';
import { moocAPI } from '../services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ExerciseAPI from '../components/activities/ExerciseAPI';
import { Session } from '../components/activities/ActivityContent';
import { useStoreWithArray } from '@recourseai/components/src/stores';

export const useExerciseActivity = (activityId: string, courseId: string) => {
    const queryClient = useQueryClient();
    const throwAsyncError = useAsyncError();
    const queryKey = ['course', courseId, 'activity', activityId];

    const { data: activity, refetch: refetchActivity } = useQuery<Activity>(
        queryKey,
        () => moocAPI.get(`course/${courseId}/activities/${activityId}/`),
        {
            onError: throwAsyncError,
            // There's initial data loaded from the course page
            refetchOnMount: false,
        },
    );

    const activeAttempt = (activity?.student_activity?.attempts || []).find(
        attempt => attempt.completed_datetime === null,
    );

    const exerciseAPI = useMemo(
        () => (activity ? new ExerciseAPI(activity) : undefined),
        [activity],
    );

    const completeAttempt = useMutation({
        mutationFn: async (attemptId: number) => {
            await moocAPI.patch(
                `course/${courseId}/activity/${activityId}/attempt/${attemptId}/complete/`,
            );
        },
        onSuccess: () => {
            queryClient.refetchQueries(['course', courseId], { exact: true });
            queryClient.refetchQueries(['course', courseId, 'activities'], {
                exact: true,
            });
            queryClient.refetchQueries(['courses', 'in-progress'], {
                exact: true,
            });
            queryClient.refetchQueries(['courses', 'completed'], {
                exact: true,
            });
            queryClient.refetchQueries(queryKey, { exact: true });
        },
    });

    const startAttempt = useMutation({
        mutationFn: async () => {
            if (!exerciseAPI || !activity) {
                console.error(
                    'Tried to start attempt without any activity data',
                );
                return;
            }

            const sessionDetails = await exerciseAPI.startSession();
            const attemptData = (await moocAPI.post(
                `course/${courseId}/activity/${activityId}/attempt/`,
                {
                    exercise_session_id: sessionDetails.id,
                },
            )) as StudentExerciseAttempt;

            if (!activity.student_activity) {
                await queryClient.refetchQueries(queryKey, { exact: true });
            } else {
                queryClient.setQueryData(queryKey, oldActivityData => {
                    const newActivityData: Activity = JSON.parse(
                        JSON.stringify(oldActivityData),
                    );
                    newActivityData.student_activity?.attempts.push(
                        attemptData,
                    );
                    return newActivityData;
                });
            }
        },
        onSuccess: () => {
            queryClient.refetchQueries(['course', courseId], { exact: true });
            queryClient.refetchQueries(['course', courseId, 'activities'], {
                exact: true,
            });
            queryClient.refetchQueries(['courses', 'in-progress'], {
                exact: true,
            });
            queryClient.refetchQueries(['courses', 'available'], {
                exact: true,
            });
        },
    });

    const cancelAttempt = useMutation({
        mutationFn: async (attempt: StudentExerciseAttempt) => {
            if (!exerciseAPI || !activity) {
                console.error(
                    'Tried to cancel attempt without any activity data',
                );
                return;
            }
            await exerciseAPI.cancelSession(attempt.exercise_session_id);
            await moocAPI.delete(
                `course/${courseId}/activity/${activityId}/attempt/${attempt.id}/cancel/`,
            );
            await queryClient.refetchQueries(queryKey);
        },
        onSuccess: () => {
            queryClient.refetchQueries(['course', courseId, 'activities'], {
                exact: true,
            });
        },
    });

    return {
        activity,
        refetchActivity,
        activeAttempt,
        exerciseAPI,
        completeAttempt,
        startAttempt,
        cancelAttempt,
    };
};

export const useAttemptFeedback = (
    courseId?: number,
    activityId?: number,
    attemptId?: number,
) => {
    return useQuery(
        ['attempt-feedback', attemptId],
        () =>
            moocAPI.get(
                `course/${courseId}/activity/${activityId}/attempt/${attemptId}/feedback/`,
            ),
        {
            enabled:
                attemptId !== undefined &&
                activityId !== undefined &&
                courseId !== undefined,
            keepPreviousData: true,
            useErrorBoundary: true,
        },
    );
};

export const useInteractionSession = (
    exerciseAPI?: ExerciseAPI,
    sessionId?: string,
) => {
    const throwAsyncError = useAsyncError();
    const sessionQueryKey = [
        'interaction',
        exerciseAPI?.baseUrl,
        'session',
        sessionId,
    ];

    return useQuery<Session>(
        sessionQueryKey,
        () => exerciseAPI!.sessionDetails(sessionId!),
        {
            enabled: !!exerciseAPI && !!sessionId,
            cacheTime: 0,
            onError: throwAsyncError,
        },
    );
};

export const useAttempt = (activityId: string, courseId: string) => {
    const {
        activity,
        refetchActivity,
        activeAttempt,
        startAttempt: { mutate: startAttemptMutate },
        exerciseAPI,
        completeAttempt: {
            mutate: completeAttemptMutate,
            isLoading: isCompleting,
            isSuccess: isComplete,
            isError: hasFailedToComplete,
        },
    } = useExerciseActivity(activityId, courseId);

    // Set the attempt based on the active attempt
    // or start an attempt
    const [attempt, setAttempt] = useState<StudentExerciseAttempt | null>(null);
    useEffect(() => {
        if (activity?.id !== undefined && attempt === null) {
            // Start an attempt if not already existing
            if (activeAttempt === undefined) {
                startAttemptMutate();
            } else {
                setAttempt(activeAttempt);
            }
        }
    }, [activeAttempt, activity?.id, attempt, startAttemptMutate]);

    const { data: session } = useInteractionSession(
        exerciseAPI,
        attempt?.exercise_session_id,
    );

    const { data: feedback } = useAttemptFeedback(
        +courseId,
        +activityId,
        isComplete ? attempt?.id : undefined,
    );

    const reset = useCallback(async () => {
        await refetchActivity();
        setAttempt(null);
    }, [refetchActivity]);

    return {
        attempt,
        reset,
        activity,
        isComplete,
        isCompleting,
        hasFailedToComplete,
        completeAttempt: completeAttemptMutate,
        exerciseAPI,
        session,
        feedback,
    };
};

export const useActivityInitialConfig = (initialConfig: {
    mode: 'AVATAR' | 'TEXT';
}) => {
    const { setIsTextMode } = useStoreWithArray(['setIsTextMode']);

    useEffect(() => {
        if (initialConfig === undefined) return;

        if (initialConfig.mode === 'AVATAR') {
            setIsTextMode(false);
        } else {
            setIsTextMode(true);
        }
    }, [initialConfig, setIsTextMode]);
};
