import { useCallback, useEffect } from 'react';
import { useStoreWithArray } from '../../stores';
import useASR from '../../templates/AvatarTemplate/useASR';
import { useWebSocket, WebSocketState } from '../useWebsocket';
import { useMicrophone } from './useMicrophone';

export interface SpeechRecognitionProps {
    generateWebsocketUrl: () => Promise<string>;

    sampleRate?: number;
    transcriber_config: TranscriberConfig;
    processor_configs: TranscriptionProcessorConfig[];
}

const isSpeechEvent = (event: Record<string, any>): event is SpeechEvent =>
    ['speech_start', 'speech_transcript', 'speech_end'].includes(event.name);

const parseSpeechEvent = (rawEvent: string) => {
    try {
        const event = JSON.parse(rawEvent);
        if (isSpeechEvent(event)) {
            return event;
        }
    } catch (err) {
        /* empty */
    }
};

export const useRemoteSpeechRecognition = ({
    generateWebsocketUrl,
    sampleRate = 16000,
    transcriber_config,
    processor_configs,
}: SpeechRecognitionProps) => {
    const { micListeners } = useStoreWithArray(['micListeners']);
    const { onMessageRecognised, sendBuffer } = useASR();

    const onMessage = useCallback(
        (message: string) => {
            console.log(`[ASR]: message received`, message);
            const speechEvent = parseSpeechEvent(message);
            if (
                speechEvent?.name === 'speech_transcript' &&
                speechEvent?.is_final
            ) {
                // TODO: use the first alternative for now?
                onMessageRecognised(speechEvent.alternatives[0]);
            }
        },
        [onMessageRecognised],
    );

    const { state, send, sendEvent, connect, disconnect } = useWebSocket({
        generateWebsocketUrl: generateWebsocketUrl,
        onMessage: onMessage,
    });
    const { toggle: toggleMicrophone } = useMicrophone(send);

    const sendStartEvent = useCallback(() => {
        console.log(`[ASR]: sending start event`, transcriber_config);
        sendEvent({
            type: 'start',
            transcriber_config: {
                ...transcriber_config,
                sample_rate: sampleRate,
            },
            processor_configs: processor_configs,
        });
    }, [sendEvent, sampleRate, transcriber_config, processor_configs]);

    const sendStopEvent = useCallback(() => {
        console.log(`[ASR]: sending stop event`);
        sendEvent({ type: 'stop' });
    }, [sendEvent]);

    useEffect(() => {
        if (state === WebSocketState.Connected) {
            sendStartEvent();
        }
    }, [state, sendStartEvent]);

    const toggleSpeechRecognition = useCallback(
        async (shouldListen: boolean) => {
            if (shouldListen) {
                await connect();
            } else {
                sendStopEvent();
                disconnect();
            }

            await toggleMicrophone(shouldListen);
        },
        [connect, disconnect, toggleMicrophone, sendStopEvent],
    );

    useEffect(() => {
        console.log('[ASR]: setting up the effect');
        micListeners.add(toggleSpeechRecognition);
        micListeners.add(sendBuffer);

        return () => {
            micListeners.delete(toggleSpeechRecognition);
            micListeners.delete(sendBuffer);
        };
    }, [micListeners, toggleSpeechRecognition, sendBuffer]);
};
