import React, { useMemo } from 'react';
import {
    BookOutline,
    NotesMedicalOutline,
    PenWritingOutline,
    PersonOutline,
    VideoOutline,
} from '../../icons';
import { Icon, IconProps, ImageProps, Image } from '@chakra-ui/react';

export const ActivityIcon: React.FC<IconProps & { activity: Activity }> = ({
    activity,
    ...iconProps
}) => {
    const getIconType = (activity: Activity): any => {
        switch (activity.type) {
            case 'exercise':
                switch (activity.config.exercise_type) {
                    case 'CONVERSATION':
                    case 'DIALOGUE_CHOICES':
                    case 'TUTOR':
                        return PersonOutline;
                    case 'INVESTIGATION':
                        return NotesMedicalOutline;
                }
                break;
            case 'reading':
                return BookOutline;
            case 'video':
                return VideoOutline;
            case 'writing_assignment':
                return PenWritingOutline;
        }
        return PersonOutline;
    };

    return <Icon {...iconProps} as={getIconType(activity)} />;
};

export const ActivityImage: React.FC<ImageProps & { activity: Activity }> = ({
    activity,
    ...imgProps
}) => {
    const imgSrc = useMemo(() => {
        if (activity.image_url) {
            return activity.image_url;
        }
        switch (activity.type) {
            case 'exercise':
                switch (activity.config.exercise_type) {
                    case 'CONVERSATION':
                    case 'DIALOGUE_CHOICES':
                    case 'TUTOR':
                        return 'https://storage.googleapis.com/recourseai-assets/images/thumbnails/consultation-stock.jpg';
                    case 'INVESTIGATION':
                        return 'https://storage.googleapis.com/recourseai-assets/images/thumbnails/orders-stock.jpg';
                }
                break;
            case 'reading':
                return 'https://storage.googleapis.com/recourseai-assets/images/thumbnails/reading-stock.jpg';
            case 'video':
                return 'https://storage.googleapis.com/recourseai-assets/images/thumbnails/video-stock.jpg';
            case 'writing_assignment':
                return 'https://storage.googleapis.com/recourseai-assets/images/thumbnails/soap-note-stock.jpg';
        }
        return 'https://storage.googleapis.com/recourseai-assets/images/thumbnails/consultation-stock.jpg';
    }, [activity]);

    return <Image src={imgSrc} {...imgProps} />;
};

export default ActivityIcon;
