import React, { Fragment, useEffect, useState } from 'react';
import AvatarWrapper, {
    Props as AvatarProps,
} from '../../organisms/AvatarWrapper/AvatarWrapper';
import AvatarUIOverlayTemplate from '../AvatarUIOverlayTemplate/AvatarUIOverlayTemplate';
import { Props as ChatProps } from '../../organisms/Chat/Chat';
import { Center } from '@chakra-ui/react';
import LoadingSpinner from '../../../../../apps/mooc-frontend/src/components/generic/LoadingSpinner';
import { useStoreWithArray } from '../../stores';
import {
    RAPPORT_SESSION_COULD_NOT_INITIALISE,
    RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
    RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
    RapportModalName,
    RapportSessionCouldNotInitialiseModal,
    RapportSessionDisconnectedTimeoutModal,
    RapportSessionDisconnectedTtsFailedModal,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/RapportModalTemplates';
import AvatarWrapperSimli from '../../organisms/AvatarWrapper/AvatarWrapperSimli';
import useRapportOrchestrator from './useRapportOrchestrator';
import {
    SpeechRecognitionProps,
    useRemoteSpeechRecognition,
} from '../../hooks/speech/useRemoteSpeechRecognition';

const RAPPORT_SCENE_ID = 'rapportScene';
interface Props
    extends ChatProps,
        Omit<AvatarProps, 'rapportSceneId'>,
        Pick<InteractionStage, 'transcriber_config'>,
        Pick<InteractionStage, 'processor_configs'>,
        Pick<SpeechRecognitionProps, 'generateWebsocketUrl'> {}

export function RapportAvatarTemplate(props: Props) {
    const [modal, setModal] = useState<RapportModalName | null>();

    useRemoteSpeechRecognition({
        generateWebsocketUrl: props.generateWebsocketUrl,
        transcriber_config: props.transcriber_config,
        processor_configs: props.processor_configs,
    });

    const { status, initRapport } = useRapportOrchestrator(
        RAPPORT_SCENE_ID,
        props.avatar_config,
        setModal,
    );
    const { setIsTextMode } = useStoreWithArray(['setIsTextMode']);

    const isLoading = status === 'connecting';

    return (
        <Fragment>
            <AvatarWrapper rapportSceneId={RAPPORT_SCENE_ID} {...props} />

            {isLoading && (
                <Center position='fixed' w='100%' h='100%' top='0'>
                    <LoadingSpinner />
                    <span className='ml-2'> Loading </span>
                </Center>
            )}
            {!isLoading && <AvatarUIOverlayTemplate {...props} />}

            <RapportSessionCouldNotInitialiseModal
                show={modal === RAPPORT_SESSION_COULD_NOT_INITIALISE.code}
                onClose={() => setModal(null)}
                useTextChat={() => {
                    setIsTextMode(true);
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    initRapport(() => setModal(null));
                }}
            />
            <RapportSessionDisconnectedTtsFailedModal
                show={
                    modal ===
                    RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                useTextChat={() => {
                    setIsTextMode(true);
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    initRapport(() => setModal(null));
                }}
            />
            <RapportSessionDisconnectedTimeoutModal
                show={
                    modal ===
                    RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                finish={() => {
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    initRapport(() => setModal(null));
                }}
            />
        </Fragment>
    );
}

export function SimliAvatarTemplate(props: Props) {
    const [simliConfig, setSimliConfig] = useState({
        apiKey: '',
        faceID: '',
        handleSilence: true,
        maxSessionLength: 3600, // in seconds
        maxIdleTime: 600, // in seconds
        greenscreen: false,
    });

    useRemoteSpeechRecognition({
        generateWebsocketUrl: props.generateWebsocketUrl,
        transcriber_config: props.transcriber_config,
        processor_configs: props.processor_configs,
    });

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        setSimliConfig(state => ({
            ...state,
            apiKey: query.get('apiKey') || '',
            faceID: query.get('faceID') || '',
            greenscreen: query.get('greenscreen') !== null || false,
        }));
    }, []);

    return (
        <Fragment>
            {!!simliConfig['apiKey'] && (
                <AvatarWrapperSimli
                    simliConfig={simliConfig}
                    backgroundSrc={props.backgroundSrc}
                    useGreenScreenRemoval={simliConfig['greenscreen']}
                />
            )}
            <AvatarUIOverlayTemplate {...props} />
        </Fragment>
    );
}

export default function AvatarTemplate(props: Props) {
    const query = new URLSearchParams(window.location.search);

    if (query.has('apiKey') && query.has('faceID')) {
        return <SimliAvatarTemplate {...props} />;
    } else {
        return <RapportAvatarTemplate {...props} />;
    }
}
