export type ColorTuple = [number, number, number];

const workerFunction = function() {
    // eslint-disable-next-line no-restricted-globals
    self.onmessage = (event: MessageEvent<any>) => {
        const frame = computeFrame(
            event.data.frame,
            event.data.chromaKeyRGB,
            event.data.chromaKeyYCbCr,
            event.data.colorDistanceInterval,
        );
        postMessage(frame);
    };

    const rgbToYCbCr = (r: number, g: number, b: number) => {
        const y = 0.299 * r + 0.587 * g + 0.114 * b + 0;
        const cb = -0.169 * r + -0.331 * g + 0.5 * b + 128;
        const cr = 0.5 * r + -0.419 * g + -0.081 * b + 128;
        return [y, cb, cr];
    };

    const euclidianDistance = (
        x1: number,
        y1: number,
        x2: number,
        y2: number,
    ) => {
        return Math.sqrt(Math.pow(y1 - y2, 2) + Math.pow(x1 - x2, 2));
    };

    const percentageOfInterval = (x: number, interval: [number, number]) => {
        if (x < interval[0]) {
            return 0;
        } else if (x < interval[1]) {
            return (x - interval[0]) / (interval[1] - interval[0]);
        } else {
            return 1;
        }
    };

    const kernel = (
        arr: number[],
        size: number,
        i: number,
        filterPixel?: (r: number, g: number, b: number, a: number) => boolean,
    ) => {
        const result = [];

        // Assuming a square image
        const imageSize = Math.sqrt(arr.length / 4);
        const row = Math.floor(i / imageSize);
        const col = i % imageSize;

        // Define the radius based on kernel size
        const radius = Math.floor(size / 2);

        // Loop over the neighborhood defined by the kernel size
        for (let dy = -radius; dy <= radius; dy++) {
            for (let dx = -radius; dx <= radius; dx++) {
                const x = col + dx;
                const y = row + dy;

                if (x >= 0 && x < imageSize && y >= 0 && y < imageSize) {
                    // Calculate the index in the 1D array
                    const neighborIndex = (y * imageSize + x) * 4;

                    // Extract RGB values of the neighboring pixel
                    const r = arr[neighborIndex];
                    const g = arr[neighborIndex + 1];
                    const b = arr[neighborIndex + 2];
                    const a = arr[neighborIndex + 3];

                    // Apply the filter function to the pixel
                    if (filterPixel === undefined || filterPixel(r, g, b, a)) {
                        result.push([r, g, b, a]);
                    }
                }
            }
        }
        return result;
    };

    // http://gc-films.com/chromakey.html
    const computeFrame = (
        frame: ImageData,
        chromaKeyRGB: ColorTuple,
        chromaKeyYCbCr: ColorTuple,
        colorDistanceInterval: [number, number],
    ) => {
        const l = frame.data.length / 4;

        const boundryPixels = [];

        for (let i = 0; i < l; i++) {
            const r = frame.data[i * 4 + 0];
            const g = frame.data[i * 4 + 1];
            const b = frame.data[i * 4 + 2];
            const [, cb, cr] = rgbToYCbCr(r, g, b);

            const distanceFromGreen = euclidianDistance(
                cb,
                cr,
                chromaKeyYCbCr[1],
                chromaKeyYCbCr[2],
            );

            const pixelAlpha = percentageOfInterval(
                distanceFromGreen,
                colorDistanceInterval,
            );

            const [r_key, g_key, b_key] = chromaKeyRGB;

            frame.data[i * 4 + 3] = 255 * pixelAlpha;

            if (0 < pixelAlpha && pixelAlpha < 1) {
                const mask = (1 - pixelAlpha) / 2;
                boundryPixels.push(i);
                frame.data[i * 4 + 0] = Math.max(r - mask * r_key, 0);
                frame.data[i * 4 + 1] = Math.max(g - mask * g_key, 0);
                frame.data[i * 4 + 2] = Math.max(b - mask * b_key, 0);
            }
        }

        return frame;
    };
};

//This stringifies the whole function
const codeToString = workerFunction.toString();
//This brings out the code in the bracket in string
const mainCode = codeToString.substring(
    codeToString.indexOf('{') + 1,
    codeToString.lastIndexOf('}'),
);
//convert the code into a raw data
const blob = new Blob([mainCode], { type: 'application/javascript' });
//A url is made out of the blob object and we're good to go
const worker_script = URL.createObjectURL(blob);

export default worker_script;
