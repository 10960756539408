import { useStoreWithArray } from '../../stores';
import {
    InteractionContextAPI,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import useRapport from '../../organisms/AvatarWrapper/useRapport';
import { useCallback, useEffect } from 'react';
import {
    RAPPORT_SESSION_COULD_NOT_INITIALISE,
    RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
    RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
    RapportModalName,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/RapportModalTemplates';

const VIDEO_CLIP_PATH =
    'polygon(50% 0px, 80% 10%, 100% 35%, 100% 100%, 0% 100%, 0px 35%, 20% 10%)';

if (window.shouldWaitRapportDisconnect === undefined) {
    window.shouldWaitRapportDisconnect = false;
}

const useRapportOrchestrator = (
    rapportComponentId: string,
    avatar_config: RapportAvatarConfig,
    setModal: (modal: RapportModalName) => void,
) => {
    const { audioListeners } = useStoreWithArray(['audioListeners']);

    const { addActionProcessor, removeActionProcessor } = useInteractionContext(
        InteractionContextAPI,
    );

    const {
        initialiseRapport: _initialiseRapport,
        speakProcessor,
        applyMask,
        status,
        toggleAvatarAudio,
        disconnect,
    } = useRapport(rapportComponentId, avatar_config);

    const initListeners = useCallback(() => {
        audioListeners.add(toggleAvatarAudio);
        const onTtsTimeout = () => {
            disconnect();
            setModal(
                RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE.code,
            );
        };
        addActionProcessor('audio', (action, activeStage) => {
            return speakProcessor(action, activeStage, 10, onTtsTimeout);
        });
    }, [
        addActionProcessor,
        audioListeners,
        disconnect,
        setModal,
        speakProcessor,
        toggleAvatarAudio,
    ]);

    const cleanupListeners = useCallback(() => {
        removeActionProcessor('audio');
        audioListeners.delete(toggleAvatarAudio);
    }, [audioListeners, removeActionProcessor, toggleAvatarAudio]);

    const initRapport = useCallback(
        (onConnect?: () => void, onError?: (err: any) => void) => {
            _initialiseRapport(
                () => {
                    onConnect && onConnect();
                    applyMask(VIDEO_CLIP_PATH);
                    initListeners();
                },
                err => {
                    onError && onError(err);
                    if (!!err.code && err.code.includes('TIMEOUT')) {
                        setModal(
                            RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE.code,
                        );
                    } else {
                        setModal(RAPPORT_SESSION_COULD_NOT_INITIALISE.code);
                    }
                    cleanupListeners();
                },
            );
        },
        // These are all methods and are expected not to change
        [
            _initialiseRapport,
            applyMask,
            cleanupListeners,
            initListeners,
            setModal,
        ],
    );

    useEffect(() => {
        console.debug('Avatar wrapper load: initialising rapport');
        let isMounted = true;
        const manageConnection = () => {
            isMounted && initRapport();
        };

        // This covers two cases
        // 1. Switching between text and avatar where the disconnect does not complete before going back to avatar mode
        // 2. During development when reloading causes this effect to clean-up and run again instantly
        if (window.shouldWaitRapportDisconnect) {
            window.rapportDisconnectedPromise = manageConnection;
        } else {
            manageConnection();
        }

        return () => {
            console.debug('Avatar wrapper un-load: disconnecting rapport');
            cleanupListeners();
            isMounted = false;

            window.shouldWaitRapportDisconnect = true;
            void disconnect().then(() => {
                window.shouldWaitRapportDisconnect = false;
                if (window.rapportDisconnectedPromise) {
                    window.rapportDisconnectedPromise();
                    window.rapportDisconnectedPromise = undefined;
                }
            });
        };
    }, [cleanupListeners, disconnect, initRapport]);

    return { status, initRapport, initListeners, cleanupListeners };
};

export default useRapportOrchestrator;
