import {
    InteractionContextAPI,
    InteractionContextStatus,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import useRefOfState from '../../../../core/src/hooks/useRefOfState';
import { useCallback, useRef } from 'react';
import { useStore } from '../../stores';
import { UserMessageSource } from '../../organisms/Chat/Chat';

const useASR = () => {
    const { awaitingResponse, isAgentBusy } = useInteractionContext(
        InteractionContextStatus,
    );

    const awaitingResponseRef = useRefOfState(awaitingResponse);
    const isAgentBusyRef = useRefOfState(isAgentBusy);

    const { sendMessage } = useInteractionContext(InteractionContextAPI);

    const asrBuffer = useRef('');

    const onMessageRecognised = useCallback(
        (text: string) => {
            if (!awaitingResponseRef.current && !isAgentBusyRef.current) {
                const isMicOn = useStore.getState().isMicOn;
                if (isMicOn) {
                    asrBuffer.current += text + ' ';
                } else {
                    sendMessage(text, {
                        message_source:
                            UserMessageSource.CLOUD_RECOGNISED_SPEECH,
                    });
                }
                console.log('buffer', asrBuffer.current);
            } else {
                console.debug(
                    'Skipped recognised speech because avatar was busy',
                );
            }
        },
        [awaitingResponseRef, isAgentBusyRef, sendMessage],
    );

    const sendBuffer = useCallback(
        (micOn: boolean) => {
            if (!micOn && asrBuffer.current.length) {
                sendMessage(asrBuffer.current, {
                    message_source: UserMessageSource.CLOUD_RECOGNISED_SPEECH,
                });
                asrBuffer.current = '';
            }
        },
        [sendMessage],
    );

    return {
        onMessageRecognised,
        sendBuffer,
    };
};

export default useASR;
