// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import worker_script, { ColorTuple } from './greenscreenremoval';

const rgbToYCbCr = (r: number, g: number, b: number) => {
    const y = 0.299 * r + 0.587 * g + 0.114 * b + 0;
    const cb = -0.169 * r + -0.331 * g + 0.5 * b + 128;
    const cr = 0.5 * r + -0.419 * g + -0.081 * b + 128;
    return [y, cb, cr];
};

const CHROMA_KEY_GREEN_RGB: ColorTuple = [25, 255, 25];
const CHROMA_KEY_GREEN_YCbCr = rgbToYCbCr(...CHROMA_KEY_GREEN_RGB);

const COLOR_DISTANCE_INTERVAL = [60, 120]; // Obtained via experimentation
let colorDistanceInterval = COLOR_DISTANCE_INTERVAL; // Obtained via experimentation

// https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Manipulating_video_using_canvas
const processor = {
    timerCallback: function() {
        if (this.video.paused || this.video.ended) {
            return;
        }
        this.computeFrame();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        setTimeout(() => self.timerCallback(), 15);
    },

    doLoad: function(event: Event) {
        this.video = event.target as HTMLVideoElement;
        this.c1 = document.getElementById('c1') as HTMLCanvasElement;
        this.ctx1 = this.c1.getContext('2d');
        this.c2 = document.getElementById('c2') as HTMLCanvasElement;
        this.ctx2 = this.c2.getContext('2d');

        this.frameProcessorWorker = new Worker(worker_script);
        this.frameProcessorWorker.addEventListener('message', ev => {
            this.ctx2.putImageData(ev.data, 0, 0);
        });

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        this.video.addEventListener(
            'play',
            function() {
                self.width = self.video.videoWidth * window.devicePixelRatio;
                self.height = self.video.videoHeight * window.devicePixelRatio;
                self.c1.width = self.width;
                self.c1.height = self.height;
                self.c2.width = self.width;
                self.c2.height = self.height;
                self.timerCallback();
            },
            false,
        );
    },

    // http://gc-films.com/chromakey.html
    computeFrame: async function() {
        this.ctx1.drawImage(this.video, 0, 0, this.width, this.height);
        const frame = this.ctx1.getImageData(0, 0, this.width, this.height);

        this.frameProcessorWorker.postMessage({
            frame,
            chromaKeyRGB: CHROMA_KEY_GREEN_RGB,
            chromaKeyYCbCr: CHROMA_KEY_GREEN_YCbCr,
            colorDistanceInterval,
        });
    },
};

type Props = {
    showOriginal?: boolean;
    applyChromaKeyRemoval?: boolean;
} & React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
>;
const GreenScreenVideo = forwardRef((props: Props, ref) => {
    const {
        showOriginal = false,
        applyChromaKeyRemoval = true,
        style,
        ...videoProps
    } = props;

    useEffect(() => {
        if (!applyChromaKeyRemoval) {
            colorDistanceInterval = [0, 0];
        } else {
            colorDistanceInterval = COLOR_DISTANCE_INTERVAL;
        }
    }, [applyChromaKeyRemoval]);

    return (
        <Flex style={style}>
            <canvas id='c2' style={style} />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                ref={ref}
                {...videoProps}
                style={{
                    ...style,
                    display: !showOriginal ? 'none' : undefined,
                }}
                onLoadedData={event => {
                    processor.doLoad(event);
                    if (props.onLoadedData) return props.onLoadedData(event);
                }}
            />
            <canvas
                id='c1'
                style={{
                    ...style,
                    display: 'none',
                }}
            />
        </Flex>
    );
});
GreenScreenVideo.displayName = 'GreenScreenVideo';

export default GreenScreenVideo;
