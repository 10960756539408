import { useBreakpointValue } from '@chakra-ui/react';

export default function useStyling() {
    const sourcesSidePanelWidth = useBreakpointValue({
        base: '100%',
        md: '40%',
        lg: '30%',
        xl: '20%',
    });

    return { sourcesSidePanelWidth };
}
