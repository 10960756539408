import React from 'react';
import { Box, Center, Circle } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const IndexNumber: React.FC<{
    activity: Activity;
    index: number;
    isActive?: boolean;
}> = ({ isActive, activity, index }) => {
    if (activity.complete || activity.student_activity?.completed_datetime) {
        return (
            <Circle size='2em' bgColor='green.400' textColor='white'>
                <FontAwesomeIcon icon={faCheck} />
            </Circle>
        );
    } else if (isActive) {
        return (
            <Center position='relative'>
                <Box position='absolute' top='-5'>
                    <FontAwesomeIcon
                        color='black'
                        icon={faChevronDown}
                        size='lg'
                    />
                </Box>
                <Circle
                    size='2em'
                    bgColor='black'
                    textColor='white'
                    fontWeight='bold'
                >
                    {index}
                </Circle>
            </Center>
        );
    } else
        return (
            <Circle
                size='2em'
                borderColor='black'
                borderWidth='2px'
                fontWeight='bold'
            >
                {index}
            </Circle>
        );
};

export default IndexNumber;
