import { useCallback, useRef, useState } from 'react';

export enum WebSocketState {
    NotConnected = 'not_connected',
    Connecting = 'connecting',
    Connected = 'connected',
    Closing = 'closing',
    Error = 'error',
}

interface WebSocketOptions {
    generateWebsocketUrl: () => Promise<string>;
    retryAttempts?: number;
    retryDelay?: number;
    onMessage: (event: string) => void;
    onError?: (event: Event) => void;
}

function useWebSocket({
    generateWebsocketUrl,
    retryAttempts = 3,
    retryDelay = 1000,
    onMessage,
    onError,
}: WebSocketOptions) {
    const [state, setState] = useState<WebSocketState>(
        WebSocketState.NotConnected,
    );
    const wsRef = useRef<WebSocket | null>(null);
    const retriesRef = useRef(0);
    const reconnectTimeoutRef = useRef<NodeJS.Timeout>();

    const connect = useCallback(async () => {
        console.log('[useWebsocket]: connect called...');
        if (wsRef.current?.readyState === WebSocket.OPEN) return;

        try {
            setState(WebSocketState.Connecting);
            const url = await generateWebsocketUrl();
            wsRef.current = new WebSocket(url);

            wsRef.current.onopen = () => {
                console.log('[Websocket]: connection successfully opened');
                setState(WebSocketState.Connected);
                retriesRef.current = 0; // Reset retry counter on successful connection
            };

            wsRef.current.onclose = e => {
                setState(WebSocketState.NotConnected);
                const shouldRetry = ![1000].includes(e.code);
                console.log('[Websocket]: connection closed', e);

                // Try to reconnect if we haven't exceeded retry attempts
                if (shouldRetry && retriesRef.current < retryAttempts) {
                    retriesRef.current += 1;
                    reconnectTimeoutRef.current = setTimeout(() => {
                        console.log(
                            `[Websocket]: reconnecting... attempt=${retriesRef.current}`,
                        );
                        connect();
                    }, retryDelay * retriesRef.current); // Exponential backoff
                }
            };

            wsRef.current.onerror = event => {
                console.log('[Websocket]: on error', event);
                setState(WebSocketState.Error);
                onError?.(event);
            };

            wsRef.current.onmessage = ({ data }) => onMessage(data);
        } catch (err) {
            setState(WebSocketState.Error);
            console.error('WebSocket connection error:', err);
        }
    }, [generateWebsocketUrl, retryAttempts, retryDelay, onMessage, onError]);

    const disconnect = useCallback(() => {
        console.log('[useWebsocket]: disconnect called...');
        setState(WebSocketState.Closing);

        // Clear any pending reconnection attempts
        if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
        }

        if (wsRef.current) {
            wsRef.current.close(1000);
            wsRef.current = null;
        }

        setState(WebSocketState.NotConnected);
    }, []);

    const send = useCallback((data: string | ArrayBuffer | Blob) => {
        if (wsRef.current?.readyState === WebSocket.OPEN) {
            wsRef.current.send(data);
            return true;
        }
        return false;
    }, []);

    const sendEvent = useCallback(
        (event: TranscriptionRequest) => send(JSON.stringify(event)),
        [send],
    );

    return {
        state,
        send,
        sendEvent,
        connect,
        disconnect,
    };
}

export { useWebSocket };
